import React from "react";
import Inprogress from "./Inprogress";
import aboutimg from "../assets/aboutimg.webp";
import Buttonopen from "./Buttonopen";
import pdf from "../assets/林伯晏＿履歷.pdf";

const About = () => {
  return (
    <div className="container intro ">
      <div
        className={
          window.innerWidth < 800
            ? "container-intro flex-column-reverse_center "
            : "container-intro flex-row-around"
        }
      >
        <div className="aboutContainerImg colorblock">
          <h4 className="intro-h1 ">關於我。</h4>
          <p>Hi，我是Po，在超過4年的產品設計及研究職涯中持續探索精進著。</p>
          <Buttonopen text="Resume" state="" to={pdf} />
        </div>
        <div className="aboutContainerImg  ">
          <img src={aboutimg} alt="" className="aboutImg " />
        </div>
      </div>

      <div className="container flex-column-center ">
        {/* <div className=" casetitle ">
          <span>About me</span>
        </div> */}
        <div className="flex-column-center " style={{ marginBottom: "50px" }}>
          <p>
            身為產品設計師，
            <span className="highlighttext">
              我擅長以直覺有效的設計方案解決複雜的用戶問題，並樂於思考組織的願景，與各部門合作創造好用的產品
            </span>
            。在我的專業歷程中，曾與台新銀行、國泰人壽、永豐銀行等知名金融機構合作，共同優化前端的產品及後台系統，致力於讓複雜的金融產品變得更直覺；也有機會與數家節奏快速的Fintech新創公司合作，打造其核心服務內容，拓展金融科技的不同應用方式。在這些專案中，我負責
            <span className="highlighttext">
              探索使用者需求，提出設計概念，並以IA、user
              flow、wireframe等設計交付物，溝通、驗證設計方案，推動專案從概念到最終產品的過程
            </span>
            。
          </p>

          <p>
            我喜歡思考人類與世界互動的方式，很幸運在大學時期就有機會從行為經濟學的角度認識人類面對不同選擇時的心理活動，甚至近一步去探討如何幫助人們做出更符合自身利益選擇，這段時間的探索打下了我對使用者體驗敏銳度的基礎，更能洞察埋藏在使用行為背後的動機。然而在經濟學領域中，這些探索偏向實驗與解釋，我更想實際去創造能為人們生活、工作帶來正面影響的產品，因此決定繼續在研究所追求設計產品的技能。就讀研究所時我聽從導師的建議，除了鑽研研究方法外，也從大學部的課程開始修起，花更多的時間打基礎、磨練設計技巧，沈浸在完全不同的世界觀中。{" "}
            <span className="highlighttext">
              求學時期的探索旅程，讓我同時擁有商業的理性思維與對美感的追求，具備將人性的深刻洞見轉化爲可見產品的能力
            </span>
            。
          </p>
          <p>
            在工作之外，我喜歡體驗不同的活動，像是釀酒、調蠟燭、露營等。但我的真正的興趣其實更宅，
            <span className="highlighttext">
              閱讀、追劇、看電影，與朋友討論心得是我週末最常做的事，偶而也會爬山、打羽球
            </span>
            。
            我居住過五個城市、念過兩個科系，多元的成長環境讓我很容就能脫離本位思考，去同理使用者以及組織中不同角色的立場，也讓我跟任何人都能聊的來。不論你是想聊聊工作上的合作，或只是單純想聊聊設計、產品、科技，都歡迎隨時聯絡我👋！
          </p>
          <div className="sectionend"></div>
        </div>

        {/* ------------------------------------ 工作經驗----------------------------------------------- */}

        <div className="flex-column about">
          <div className="  ">
            <span>Work Experience</span>
          </div>
          <div>
            <h4> UX Designer, Stockfeel</h4>
            <p>2020/09-Now</p>

            <p>
              與PM、UI、RD團隊合作，共同為台新銀行、國泰人壽等大型金融機構合作產品的建置及優化，致力於讓複雜的金融行為轉化為直覺易用的體驗。
            </p>
            {/* <p>
              <ul>
                <li>
                  與專案經理一同檢視使用者研究資料，梳理使用流程與痛點，優化網站。
                </li>
                <li>負責優化內容的前端程式開發，讓優化內容落地。 </li>
                <li>
                  與IOS、Andriod工程師合作，規劃APP的使用流程並交付Wireframe。
                </li>
              </ul>
            </p> */}
          </div>
          <div>
            <h4> Full-Stack Designer, CAMEO</h4>
            <p>2020/02-2020/09</p>
            <p>
              從設計到程式開發，完整發揮在UX/UI及前端程式的專長，獨立完成網站優化等各式專案，提升網站流量及使用體驗。
            </p>
            {/*  <p>
              <ul>
                <li>
                  與專案經理一同檢視使用者研究資料，梳理使用流程與痛點，優化網站。
                </li>
                <li>負責優化內容的前端程式開發，讓優化內容落地。 </li>
                <li>
                  與IOS、Andriod工程師合作，規劃APP的使用流程並交付Wireframe。
                </li>
              </ul>
            </p> */}
          </div>
          <div>
            <h4> 研究助理, 國立成功大學</h4>
            <p>2018/07-2019/09</p>
            <p>
              規劃並執行研究計畫，探索使用者在行動裝置上的操作行為，撰寫研究結果報告。
            </p>
            {/*  <p>
              <ul>
                <li>
                  規劃使用者研究，包括問卷分析、質性分析、統計分析、A/B
                  Testing等研究方法。
                </li>
                <li>
                  撰寫研究計畫，提出假設、設計實驗，並收集資料進行質性及量化分析。
                </li>
                <li>透過在線上及線下管道招募使用者招募研究參與者</li>
                <li>撰寫研究報告，並介紹研究結果。</li>
              </ul>
            </p> */}
          </div>
        </div>

        {/* ------------------------------------ 教育背景----------------------------------------------- */}

        {/* <div className=" flex-column  about">
          <div className="  ">
            <span>Education</span>
          </div>
          <h4>工業設計碩士, 國立成功大學</h4>
          <p>2016~2019</p>
          <p>以商業角度理解使用者行為，並打下商業分析、數據分析基礎</p>
          <h4>經濟學學士, 國立清華大學</h4>
          <p>2011~2015</p>
          <p>以商業角度理解使用者行為，並打下商業分析、數據分析基礎</p>
        </div> */}
        {/* <ButtonIcon text=" Résumé" state="" to={pdf} /> */}
      </div>
    </div>
  );
};

export default About;
