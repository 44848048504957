import React from "react";
import { HiMail } from "react-icons/hi";

const Footer = () => {
  return (
    <div className="footer">
      <h3>Let's Talk!</h3>
      <p className="footer-p">
        Thank you for scrolling down this far, feel free to reach out😃!
      </p>
      <div className="flex-row">
        <HiMail
          style={{
            fontSize: "20px",
            marginRight: "10px",
            marginBottom: "5px",
            color: "#454545",
          }}
        />
        <p className="footer-p">henry6812@gmail.com</p>
      </div>
      <p className="footer-note">Website design and content © 2022 Po</p>
    </div>
  );
};

export default Footer;
