import React from "react";
import Inprogress from "./Inprogress";

const Contact = () => {
  return (
    <div className="">
      <Inprogress />
    </div>
  );
};

export default Contact;
