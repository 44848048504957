import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { HiOutlineMenu, HiOutlineXCircle } from "react-icons/hi";
import { FiXCircle } from "react-icons/fi";
import { animated, useSpring } from "@react-spring/web";

const Header = () => {
  const [navBar, setNavBar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const styles = useSpring({
    transform: toggle ? "translateY(0px)" : "translateY(-200px)",
    opacity: toggle ? 1 : 0,
    config: { mass: 1, tension: 200, friction: 20 },
  });
  const fixNav = () => {
    setNavBar(window.scrollY > 0 ? true : false);
    // console.log(navBar);
  };
  const toToggle = () => {
    setToggle(toggle == false ? true : false);
    // console.log(toggle);
  };
  window.addEventListener("scroll", fixNav);
  return (
    <>
      <div
        className={
          navBar == true
            ? "header fixed onscroll  center"
            : "header fixed center"
        }
      >
        <div className={navBar == true ? "nav  navscroll " : "nav "}>
          <a href="/" className="flex-row decoration-none">
            {/* <AiFillCustomerService className="font " /> */}
            <div className="identity">Po Yen</div>
            <div className="identity2"> | A Product Designer </div>
          </a>
          <div className="flex-row">
            <ul className="navList">
              <Link className="navListItem " to="/">
                Home
              </Link>
              <Link className="navListItem " to="/work">
                Project
              </Link>
              {/* <a className="navListItem" href="/playground">
                Playground
              </a> */}
              <Link className="navListItem" to="/about">
                About
              </Link>
              {/* <a className="navListItem" href="/contact">
                Contact
              </a> */}
            </ul>
            <div className="mobileNavIcon" onClick={toToggle}>
              <HiOutlineMenu className="font " />
            </div>
            <animated.div
              className={toggle == true ? "mobileNavMenu" : "display-none"}
              style={{ ...styles }}
            >
              <ul className="mobileNavul">
                <Link className="mobileNavListItem" to="/" onClick={toToggle}>
                  Home
                </Link>
                <Link
                  className="mobileNavListItem "
                  to="/work"
                  onClick={toToggle}
                >
                  Project
                </Link>
                {/* <a className="mobileNavListItem" href="/playground">
                  Playground
                </a> */}
                <Link
                  className="mobileNavListItem"
                  to="/about"
                  onClick={toToggle}
                >
                  About
                </Link>
                {/* <a className="mobileNavListItem" href="/contact">
                  Contact
                </a> */}
                <div className="" onClick={toToggle}>
                  <FiXCircle
                    style={{
                      color: "white",
                      fontSize: "40px",
                      marginTop: "40px",
                    }}
                  />
                </div>
              </ul>
            </animated.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
