import React from "react";
import { useState } from "react";
import SocialProof from "../component/SocialProof";
import IntroImg from "./IntroImg";
import TypeAnimation from "react-type-animation";
import { HiMail } from "react-icons/hi";
import ButtonIcon from "./ButtonIcon";
import ScrollBtn from "./ScrollBtn";

const Intro = ({ scroll }) => {
  return (
    <div
      className={
        window.innerWidth < 800
          ? "intro  flex-column-reverse"
          : "intro  flex-row-around"
      }
    >
      <div className="  flex-column-center">
        {/* <TypeAnimation
          cursor={true}
          sequence={["Hi 👋 I'm Po.", 4000, "A product designer", 4000]}
          wrapper="p"
          repeat={Infinity}
          className="intro-h1"
        /> */}
        {/* <p className="intro-h1">Hi 👋 I'm Po.</p> */}
        <h1 className="intro-h1">Hi 👋 I'm Po</h1>
        <p className="intro-p">
          An experienced UX and Product Designer passionate about converting
          innovative technologies into intuitive user experiences.
        </p>
        {/* <div className="socialproof">
        <SocialProof />
      </div> */}

        <ScrollBtn text="View case study" state="" to="" scroll={scroll} />
      </div>
      {/* <div className=" IntroImgContainer">
        <IntroImg />
      </div> */}
    </div>
  );
};

export default Intro;
