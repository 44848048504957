import React from "react";
import { useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import brand from "../assets/brand.webp";
import brandMobile from "../assets/brandMobile.png";

const SocialProof = () => {
  const [toggle, setToggle] = useState(false);
  const style1 = useSpring({
    // width: toggle ? "1.5px" : "1px",
    opacity: toggle ? 1 : 0.6,
    transform: toggle ? "translateY(-10px)" : "translateY(0px)",
    backgroundColor: toggle ? "#05668d" : "#454545",
  });
  const style2 = useSpring({
    borderRadius: toggle ? "20%" : "50%",
    rotateZ: toggle ? 360 : 0,
    transform: toggle ? "scale(1.2)" : "scale(1)",
    opacity: toggle ? 1 : 0.6,
    backgroundColor: toggle ? "#05668d" : "#454545",
  });
  const style3 = useSpring({
    // width: toggle ? "1.5px" : "1px",
    opacity: toggle ? 1 : 0.6,
    transform: toggle ? "translateY(10px)" : "translateY(0px)",
    backgroundColor: toggle ? "#05668d" : "#454545",
  });
  return (
    <div
      className="socialproof"
      onMouseEnter={() => setToggle((prev) => !prev)}
      onMouseLeave={() => setToggle((prev) => !prev)}
    >
      <animated.div className="socialdecoline" style={{ ...style1 }} />
      <animated.div
        className="socialdecocircle"
        style={{ marginBottom: "50px", ...style2 }}
      />
      <p>Some of the brands I've worked with</p>
      <div className="">
        {/* <p>Some of the top institutions I've work with</p> */}
        {window.innerWidth < 768 ? (
          <img src={brandMobile} alt="" style={{ width: "100%" }} />
        ) : (
          <img src={brand} alt="" style={{ width: "100%" }} />
        )}
      </div>
      <animated.div
        className="socialdecocircle"
        style={{ marginTop: "70px", ...style2 }}
      />
      <animated.div className="socialdecoline" style={{ ...style3 }} />
    </div>
  );
};

export default SocialProof;
