import React from "react";
import ProjectItem from "./ProjectItem";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const ProjectListpage = () => {
  return (
    <div className="container">
      {/* <h3>Featured Project</h3> */}
      <div className="container  placeholder"></div>
      <div className="projectList flex-column">
        <ProjectItem
          project="/project1"
          role="UX design"
          tittle="連結商業目標與使用者需求：優化投資分析工具"
          discription="分析痛點，設計讓更多人想使用的投資工具。"
          img="project1Img"
          BtnText="Read More"
          color="#DADADA"
          lock="false"
        />
        <ProjectItem
          project="/project2"
          role="UX design"
          tittle="將複雜流程線上化：打造Peer to Peer募資平台"
          discription="從混沌中梳理複雜抽象的使用流程，從0開始打造線上募資平台。"
          img="project2Img"
          BtnText="Coming Soon"
          lock="true"
        />
        <ProjectItem
          project="/project3"
          role="UX design & User interview & Usability test"
          tittle="使用者研究：重塑理賠人員的工作流程"
          discription="執行使用者訪談與易用性測試挖掘洞見，翻轉繁複的工作流程，提高員工效率。"
          img="project3Img"
          BtnText="Coming Soon"
          lock="true"
        />
        {/* <ProjectItem
          project="/project3"
          role="UX design & Usability test"
          tittle="優化線上申貸流程"
          discription="執行使用者訪談與易用性測試，翻轉繁複的工作流程，提高員工效率"
          img="project3Img"
          BtnText="Coming Soon"
          lock="true"
        />
        <ProjectItem
          project="/project3"
          role="UX design"
          tittle="讓註冊流程的完成率更高"
          discription="執行使用者訪談與易用性測試，翻轉繁複的工作流程，提高員工效率"
          img="project3Img"
          BtnText="Coming Soon"
          lock="true"
        /> */}
      </div>
    </div>
  );
};

export default ProjectListpage;
