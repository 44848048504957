import React from "react";
import Inprogress from "./Inprogress";

const Project3 = () => {
  return (
    <div className="">
      <Inprogress />
    </div>
  );
};

export default Project3;
