import css from "./main.css";
import { useState, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./component/Header";
import Intro from "./component/Intro";
import SocialProof from "./component/SocialProof";
import ProjectList from "./component/ProjectList";

import React from "react";

const Home = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  // const [scroll, setScroll] = useState(true);
  // const toScroll = () => {
  //   setScroll(scroll == true ? false : true);
  // };
  return (
    <>
      {/* <div className="">
        <Header />
      </div> */}
      <div className="container-intro">
        <Intro scroll={executeScroll} />
      </div>
      <div className="container">
        <SocialProof />
      </div>
      <div className="container" ref={myRef}>
        <ProjectList />
      </div>
    </>
  );
};

export default Home;
