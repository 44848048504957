import React from "react";
import { useState } from "react";
import { HiLockClosed } from "react-icons/hi";
// import { animated, useSpring } from "@react-spring/web";

const ButtonLock = ({ text, state, to, scroll }) => {
  // const [toggle, setToggle] = useState(false);
  // const styles = useSpring({
  //   transform: toggle ? "translateX(6px)" : "translateX(0px)",
  //   config: { mass: 1, tension: 250, friction: 10 },
  // });
  return (
    <div
      className="mybtn "
      // onMouseEnter={() => setToggle((prev) => !prev)}
      // onMouseLeave={() => setToggle((prev) => !prev)}
    >
      <div>{text}</div>

      <HiLockClosed
        style={{
          fontSize: "20px",
          marginLeft: "10px",
          color: "white",
        }}
      />
    </div>
  );
};

export default ButtonLock;
