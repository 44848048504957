import React from "react";
import { useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import { animated, useSpring } from "@react-spring/web";

const Buttonopen = ({ text, state, to, scroll }) => {
  const [toggle, setToggle] = useState(false);
  const styles = useSpring({
    transform: toggle ? "translateX(6px)" : "translateX(0px)",
    config: { mass: 1, tension: 250, friction: 10 },
  });
  return (
    <a
      className="mybtn "
      href={to}
      target="_blank"
      onMouseEnter={() => setToggle((prev) => !prev)}
      onMouseLeave={() => setToggle((prev) => !prev)}
    >
      <a>{text}</a>

      <HiOutlineDownload
        style={{
          fontSize: "20px",
          marginLeft: "10px",
          color: "white",
        }}
      />
    </a>
  );
};

export default Buttonopen;
