import React from "react";
import { useState, useRef } from "react";
import Inprogress from "./Inprogress";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import img from "../assets/test.webp";
import intro2 from "../assets/project01/intro2.webp";
import pages from "../assets/project01/pages.webp";
import Before from "../assets/project01/Before.webp";
import components from "../assets/project01/components.webp";
import feature01 from "../assets/project01/feature01.webp";
import feature02 from "../assets/project01/feature02.webp";
import background from "../assets/project01/background.webp";
import define from "../assets/project01/define.webp";
import excel from "../assets/project01/excel.webp";
import issue from "../assets/project01/issue.webp";
import competitor from "../assets/project01/competitor.webp";
import pattern01 from "../assets/project01/pattern 01.webp";
import pattern02 from "../assets/project01/pattern 02.webp";
import pattern03 from "../assets/project01/pattern 03.webp";
import pattern04 from "../assets/project01/pattern 04.webp";
import pattern05 from "../assets/project01/pattern 05.webp";
import pattern06 from "../assets/project01/pattern 06.webp";
import bellshaped from "../assets/project01/bellshaped.webp";
import empathy from "../assets/project01/empathy.webp";
import interviewitems from "../assets/project01/interviewitems.webp";
import CJM from "../assets/project01/CJM.webp";
import focus from "../assets/project01/focus.webp";
import functionspec from "../assets/project01/functionspec.webp";
import IA from "../assets/project01/IA.webp";
import IAold from "../assets/project01/IAold.webp";
import IAcompare from "../assets/project01/IAcompare.webp";
import mockuptest from "../assets/project01/mockuptest.webp";
import wireframe01 from "../assets/project01/wireframe01.webp";
import wireframe02 from "../assets/project01/wireframe02.webp";
import wireframe03 from "../assets/project01/wireframe03.webp";

const Project1 = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div>
      <div
        className="container"
        style={window.innerWidth < 800 ? { height: 30 } : { height: 80 }}
      ></div>
      {/* ------------------------------------ KV ----------------------------------------------- */}
      <div className="container">
        <div className="projectKV01 flex-column-center ">
          <div className="projectKVtext flex-column-center ">
            <h1>重新設計AI投資分析工具</h1>
            <p>
              吸收五花八門財經資訊，做出合理的投資決策，是所有投資人都會面臨的難題。轉化資訊以協助決策的服務自然也發展為競爭激烈的市場，要怎麼做才能讓使用者輕鬆的理解資訊，真正愛上這個產品？
            </p>
          </div>
          <div className="projectKVimg flex-column-center ">
            <img src={img} alt="" className="projectImg" />
          </div>
        </div>
      </div>
      {/* <div className="container flex-column-center">
        <div className="flex-column-center">
          <Zoom>
            <img src={intro2} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={pages} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
      </div> */}
      {/* ------------------------------------ overview ----------------------------------------------- */}
      <div className="container overview flex-column-center">
        <h3>Brief</h3>

        <span className="sectionstart"></span>
        <div className="overviewcontent">
          <div>
            <h4>我的角色</h4>
            <p>
              身為UX designer，負責釐清現有網站架構、辨識痛點，並提出解決方案。
            </p>
          </div>
          <div>
            <h4>團隊</h4>
            <p>
              我與UI Designer、Project
              Manager及若干工程師合作，共同執行這個專案。
            </p>
          </div>
          <div>
            <h4>時程</h4>
            <p>2022/02-2022/05(3 monthes)</p>
          </div>
        </div>
        <div className="container" style={{ height: "100px" }}></div>
        <div className="flex-column container summary">
          <p>
            我會依序說明設計的過程，讓你更理解設計決策背後的脈絡，
            但你可以直接跳到
            <span className="skip" onClick={executeScroll}>
              👉這裡👈
            </span>
            看最終產品。祝你閱讀愉快！
          </p>
        </div>
      </div>
      {/* ------------------------------------ teaser ----------------------------------------------- */}

      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>Teaser</span>
          {/* <h3>Teaser</h3> */}
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={intro2} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={pages} alt="" className="contentimg-intro" />
          </Zoom>
        </div>

        <div className="sectionend"></div>
      </div>

      {/* ------------------------------------ section01 專案背景與目標----------------------------------------------- */}
      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 01</span>
          <h3>專案背景與目標</h3>
        </div>
        <div className="flex-column">
          <p>
            Findbillion是一個致力於幫助使用者進行投資決策的分析工具，然而，本應成為這個分析工具亮點的各種指標數據，卻成為產品持續成長的最大障礙：{" "}
            <span className="highlighttext">
              隨著提供的內容越來越多，使用者被淹沒在資訊的洪流中，不知該如何使用這個工具，也無法感受到這個分析工具的益處。
            </span>
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={background} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            舊版網站一次提供太多資訊，讓使用者不知該如何使用這個工具。
          </p>
        </div>
        <p className="p-end">
          我們在2022年2月開始進行這個產品的優化專案，目標是
          <span className="highlighttext">
            優化產品的體驗，讓更多人願意使用這個工具
          </span>
          。 這個目標很單純，然而具體達成的方法還需要進一步的推敲。身為UX
          designer，我的任務是去理解產品的內容、辨識有哪些潛在的痛點，並提出可行的優化方案。
        </p>

        <div className="sectionend"></div>
      </div>

      {/* ------------------------------------ section02 探索問題空間----------------------------------------------- */}
      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 02</span>
          <h3>Forming the Strategy</h3>
        </div>

        <p className="">
          投資領域充斥著許多專業知識，各家分析工具更是各有獨到之處，這些現況讓進行改版變得格外困難。
          <span className="highlighttext">
            掌握產業概況並理解使用者的期待，是專案初期要優先克服的障礙
          </span>
          ，我藉由探索以下三個問題的答案來幫助團隊建立對問題空間的理解，逐步發展出有意義的設計策略：
        </p>

        <div className="flex-column">
          <h4>1. 這個工具的獨特價值及現況是什麼？</h4>
          <p>
            若在不夠了解產品全貌的情況下貿然著手進行設計，有可能做出不適切的決策。我們首先必須釐清現階段的商業目標是什麼？這個分析工具上已經提供使用者哪些資訊與功能？有哪些顯而易見的易用性問題？我一方面去深入挖掘這個工具裡的種種，以理解他帶給使用者的獨特價值，一方面去探索有哪些潛在的痛點。
          </p>
        </div>
        <div className="flex-column">
          <h4>2. 這個產品的競品發展狀況為何？</h4>
          <p>
            在專案早期，花時間去理解這個產業的競品現況是報酬率很高的投資，除了可以幫助團隊以更高的角度綜觀大局外，
            對細微處的設計決定也有直接的幫助，現存的競品是否已建立了某些行之有年的慣例用法，讓使用者遵循著相似的心智模型？
            透過理解這些問題，能避免帶給使用者額外的學習成本，並有機會取長補短，甚至借鑑間接競品來跳脫現有框架，交付令人耳目一新的解決方案。
          </p>
        </div>
        <div className="flex-column">
          <h4>3. 投資人怎麼形成買賣的決定？</h4>
          <p>
            這個分析工具最終的目的是要幫助使用者做出買賣股票的決定，但使用者是如何做出這個決定的呢？股市裡有各種類型的投資人，
            而會使用這類產品的投資人樣貌、動機是什麼？透過探索這些使用者行為，能幫助團隊做出更明智的設計決策。
          </p>
        </div>

        <div className="flex-column-center">
          <Zoom>
            <img src={define} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            尋找這三個問題解答的過程，成為這次專案最重要的一部分。
          </p>
        </div>
        {/* <p className="notitle">
          好了，話不多說，讓我帶你走過這個探索的過程並一起揭開未知的面紗吧！
        </p> */}
        <div className="sectionend"></div>
      </div>

      {/* ------------------------------------ section03 從現有產品找到洞見----------------------------------------------- */}
      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 03</span>
          <h3>從現有產品找到洞見</h3>
        </div>
        <div className="flex-column">
          <h4>進一步理解商業目標</h4>
          <p>
            首先，我們數次與利害關係人進行會談，進一步釐清當前的產品週期與商業目標。經過會談後，我們發現Findbillion其實仍處在早期的導入階段，大部分的使用者對這個產品並不熟悉。
            <span className="highlighttext">
              當務之急是讓更多的使用者感受到這個產品的獨特價值，並建立起與其他競品的區隔
            </span>
            。我們也得知，客戶下階段的目標是建立不同等級的付費會員機制，以此擴大營利。因此打造一個穩定、便捷的註冊流程，並在對的時機引導使用者註冊，也成為這次改版的另一個重要目標。
          </p>
        </div>
        <div className="flex-column">
          <h4>深入探究現行產品</h4>
          <p>
            Findbillion裡面的資料非常繁雜且專業，若對於這些內容不夠瞭解，將很難對資訊整理做出有意義的優化。我在專案初期花時間深入理解、盤點網站上的內容及資訊架構，試著找到更多的切入點。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={excel} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            面對繁雜專業的內容，我必須花時間深入理解、盤點網站上的所有內容以及資訊架構。
          </p>
        </div>
        <p>
          投資的時間並沒有白費，對產品內容的理解確實為後續的設計決策打下堅實的基礎。同時，我們也發現了一些有趣的現象，為了輔助使用者評估股票，Findbillion提供了豐富的資訊與教學說明，然而可惜的是當前網站沒有思考資料的優先級，所有指標的呈現方式都非常相近，缺乏明確的視覺著眼點來幫助使用者很快地找到最重要的資訊；另一方面，我們也發現某些相關聯的資料未被妥善的安排在相近處，這些現狀可能增加了使用者的資訊查找負擔，導致使用者感到不便，是未來可以進行優化的切入點。
        </p>
        <div className="flex-column">
          <h4>一些顯而易見的易用性問題</h4>
          <p>
            在稽查的過程中，我們也發現了其他回報率很高的易用性問題，舉例來說，現行產品的頁面跳轉方式增加了使用者的導航負擔(navigation
            effort)，使用者從同一支股票的上層頁面到下層頁面時，是以另開新視窗的方式，導致屬於同一支股票的分析內容被拆成了數個視窗，一不注意就會迷失在無數的視窗中，也無法使用上一頁按鈕回到熟悉的頁面，這樣的情況讓產品的易用性打了折扣。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={issue} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            舊版網站的導覽設計容易讓使用者迷失在產品中，以圖中的例子來說，要看完全部的內容至少需打開三個視窗。
          </p>
        </div>

        <div className="flex-column summary">
          <h4>📌 總結 📌</h4>
          <p>
            在這個階段，我們在有限的時間內做了一次輕量的體驗稽核，發現了一些非常有價值的切入點：
          </p>
          <ol>
            <li>
              現階段的改版重點應先著重在傳達出產品的獨特價值，建立與競品的區隔
            </li>
            <li>
              流暢、穩定的註冊流程及引導登入機制對於未來的商業目標有很大的幫助
            </li>
            <li>透過深入了解現有產品的資料、架構，打下後續優化的基礎</li>
            <li>創造有意義的視覺層級，能大大幫助傳達出網站的價值</li>
            <li>
              降低網站的導覽負擔，讓使用者清楚頁面間的關係，能讓工具變得更好用
            </li>
          </ol>
        </div>
        <div className="sectionend"></div>
      </div>
      {/* ------------------------------------ section04 從競品找到洞見----------------------------------------------- */}
      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 04</span>
          <h3>從競品找到洞見</h3>
        </div>
        <div className="flex-column">
          <p>
            為了探索市場的現況，做出有意義的競品分析，我接著辨識市面上有哪些直接競品與間接競品，然而辨識這些投資工具並不容易，需要一定的投資經驗才能有較全面的知識。
            <span className="highlighttext">
              為此我訪談幾位投資多年的專家，並自行使用各家產品
            </span>
            ，最後發現市面上的投資分析產品可以大致分為三個類型，分別提供使用者不同的價值：
          </p>
        </div>
        <div className="flex-column">
          <ol>
            <li>
              <span className="highlighttext">資料收集網站</span>
              ：收集股票的相關財報資料與新聞，重點在呈現給使用者完整的基本面、消息面資訊，如奇摩股市、鉅亨網、股市公開觀測站等，都是這類型的產品。
            </li>
            <li>
              <span className="highlighttext">看盤工具</span>
              ：呈現標的的盤勢資料，重點在提供豐富的交易市場上的價量關係資訊，讓使用者做即時的進出場判斷，例如TradingView、三竹股市及各家證券商軟體。
            </li>
            <li>
              <span className="highlighttext">股票評價分析</span>
              ：透過自家獨特的演算法，提供對股票價值的評估，這類網站中的資料可能不如第一類競品中立、齊全，但價值在於其獨到的評價方式，例如財報狗、jitta、simply
              wall st等分析工具。
            </li>
          </ol>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={competitor} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">Findbillion是屬於操作建議分析類型的產品</p>
        </div>
        <div className="flex-column">
          <p className="">
            經過這一輪調查後，我們發現Findbillion更貼近第三類的產品，其直接競品是財報狗、jitta、simply
            wall st等提供股票評價分析的產品。
          </p>
        </div>
        <div className="flex-column">
          <h4>競品分析：辨識常見的設計模式</h4>
          <p>
            作為競品分析的第一步，我先辨識出在直接競品中常見的設計模式，
            <span className="highlighttext">
              這邊指的是在競品中慣用的頁面佈局、常規的功能、互動方式等
            </span>
            。找出這些共通點之所以重要，是因為他們隱含了在這個產業裡共同遵循的心智模型。當這些功能或形式在網站中出現時，
            使用者會覺得熟悉、安心；相反地，當違反或消失時，使用者會覺感到疑惑，也有機會帶來額外的學習成本。
            仔細辨識出這些模式，並思考成因，能在後續設計決策的階段更明確的掌握到創新與慣例用法的界線。
          </p>
          <p>以下是一些我在競品中發現的共同設計模式：</p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={pattern01} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            在分析的直接競品中都見到了在導覽列的搜尋框，這樣的模式讓使用者在任何頁面都可以很方便的執行搜尋，十分貼近使用這類產品的目的，是值得遵循的慣例用法。
          </p>
        </div>
        {/* <div className="flex-column">
          <p className="p-end">
            在分析的直接競品中都見到了在導覽列的收尋框，這樣的模式讓使用者在任何頁面都可以很方便的執行搜尋，十分貼近使用這類產品的目的。
          </p>
        </div> */}
        <div className="flex-column-center">
          <Zoom>
            <img src={pattern02} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            在頁面最上方呈現個股基本資訊及明顯的追蹤按鈕也是常見的設計模式。讓使用著得以快速的看到必要資訊及功能，若在同類產品中缺乏此區塊，可能會讓使用者感到困惑。
          </p>
        </div>
        {/* <div className="flex-column">
          <p className="p-end">
            在頁面最上方呈現個股基本資訊及明顯的追蹤按鈕也是常見的設計模式。讓使用著得以快速的看到必要資訊及功能，若在同類產品中缺乏此區塊，可能會讓使用者感到困惑。
          </p>
        </div> */}
        <div className="flex-column-center">
          <Zoom>
            <img src={pattern03} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            另一個共同模式是以清楚的區塊區隔不同種類的資訊，讓使用者可以更輕鬆的辨別資料內容與意義。
          </p>
        </div>
        <div className="flex-column">
          <h4>競品分析：從錯誤中學習</h4>
          <p>
            我接著在競品中尋找值得優化之處，思考背後的原因及可能的解決方案，作為優化的切入點。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={pattern04} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            simply wall
            st提供了最齊全的基本資訊，但可惜的是這些資訊因為大塊的背景圖片而變得不清楚。究其原因，或許是預期以背景圖片輔助使用者辨識出該公司所屬的產業，然而此做法恐怕效果有限，甚至可能危害到基本資訊的傳達。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={pattern05} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            jitta以明確的視覺手法呈現出了主打的jitta
            score以吸引使用者注意，是十分出色的設計決策；然而，卻沒有提供使用者關於這個分數的相關脈絡，導致明顯的特色轉變為明顯的疑惑。另外，follow按鈕雖然成功地吸引注意，但是點擊後卻很難找到對應的follow清單，讓這個功能的完整性打了折扣。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={pattern06} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            財報狗的導覽列大致上十分清楚，然而其中的「個股」選項卻專門對應到「台積電」這檔股票，與其名稱有些不符。其原因可能是該分析工具沒有對應的頁面，因此選擇顯示最常被搜尋的個股，然而不理解背後原因的使用者可能會有不小的疑惑。
          </p>
        </div>

        <div className="flex-column">
          <h4>競品分析：借鑑亮點，跳脫框架</h4>
          <p>
            最後我探索在競品中出色的設計策略，思考這些設計策略是如何更好的解決痛點及達成商業目標，在我們當前的專案中是否能有所幫助。另外也試著在間接競品中尋找可以借鑑的亮點，讓專案有機會在趨於同質化的直接競品中脫穎而出。
          </p>
          <p>
            舉例來說，直接競品中的jitta、simply wall
            st以清楚的視覺向使用者傳達了其獨特的分析方式，這對於我們的產品是非常值得參考的作法；而財報狗則用tab的方式讓使用者很方便的切換不同類型的資料，保持對網站相對位置的理解。間接競品中的TradingView則用優雅有效的方式引導使用者登入，出色的在商業目標及易用性間取得平衡。
          </p>
        </div>

        <div className="flex-column">
          <h4>競品分析後的下一步</h4>
          <p className="p-end">
            除了對競品中設計決策的分析外，諸多的競品種類也暗示了他們可能對應到了不同典型的使用者群體，這些群體對於資訊的需求可能差異很大，而得益於競品分析，我們對於使用者的需求有了較清晰的認識，得以進行有意義的使用者研究。
            <span className="highlighttext">
              至此缺乏的線索已逐漸齊全，只需再補上最後一塊拼圖：在使用分析工具輔助投資行為的情境下，使用者究竟在尋求怎樣的資訊來輔助其形成買賣的決定？
            </span>
          </p>
        </div>
        <div className="flex-column summary">
          <h4>📌 總結 📌</h4>
          <p>
            在競品分析階段，我們調查市面上的競品，並獲得了許多有價值的洞見：
          </p>
          <ol>
            <li>
              市面上的競品分為三個大種類，而Findbillion的直接競品屬於其中的操作建議分析類。
            </li>
            <li>
              辨識產業內慣用的設計模式、值得優化之處及亮點，為未來設計決策打下堅實的基礎。
            </li>
            <li>
              透過分析競品的種類，得以初步限縮目標使用者族群，做出更有意義的使用者研究。
            </li>
          </ol>
        </div>
        <div className="sectionend"></div>
      </div>

      {/* ------------------------------------ section05 從使用者找到洞見 ----------------------------------------------- */}
      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 05</span>
          <h3>從使用者找到洞見</h3>
        </div>
        <div className="flex-column">
          <p>
            透過分析現有產品及競業，我們得到了很多不錯的洞見，但要追求商業目標並滿足使用者需求，必須進一步聚焦使用者樣貌：
          </p>
          <ol>
            <li>這個產品的使用者是誰？我們要為誰開發？</li>
            <li>這些使用者的動機、痛點是什麼？</li>
          </ol>
        </div>
        <div className="flex-column">
          <h4>辨識使用者族群 確立產品規劃方向</h4>
          <p>
            {/* 即便產品已上線了一段時間，我們的客戶對於產品的使用者樣貌卻不清楚，也沒有明確的目標使用者。 */}
            我用這張使用者投資經驗分佈圖表，將投資人的投資經驗分為3個階段，與團隊初步溝通產品定位及未來的進行方向（這張圖的目的並非傳達嚴肅的統計數據，而只是作為敲門磚，幫助團隊對使用者做第一步的聚焦，其假設前提是所有投資人的投資經驗依循常態分佈）：
          </p>
          <ol>
            <li>
              <span className="highlighttext">投資初學者 </span>
              ：初入投資市場，看不懂財經指標也不懂選股，這類投資人佔少數。
            </li>
            <li>
              <span className="highlighttext">中階選股者 </span>：
              有一定投資經驗，但還在學習的過程，這類投資人的人數最多。
            </li>
            <li>
              <span className="highlighttext">進階投資者 </span>：
              投資經驗豐富，非常知道自己要看什麼資訊來達成買賣決定，這類投資人佔少數。
            </li>
          </ol>
        </div>
        <div className="flex-column-center">
          <img src={bellshaped} alt="" className="contentimg" />

          <p className="caption">
            我們假設使用者的投資經驗遵循常態分佈，中級投資人佔了大多數
          </p>
        </div>

        <div className="flex-column">
          <p>
            舊版的產品中用較扁平的資訊架構呈現諸多專業的資訊，會吸引到的使用者更偏向已經熟悉這些數據的進階投資人，然而，對資訊還沒那麼熟悉的初、中階投資人來說，會顯得門檻太高。
            回顧此次改版的目標，是要吸引更多人使用這個工具，而轉向初階投資人族群與現有產品的方向差異太大，並不是選項之ㄧ，因此現在擺在我們面前的方向有兩個：
            <span className="highlighttext">
              繼續深耕進階投資人族群，或者轉為拓展中階投資人族群？
            </span>
          </p>
          <p>
            然而，若選擇繼續深耕進階投資人族群會有兩個可預見的障礙，第一是進階投資人大多已有一套慣用的投資方法，這也導致他們對慣用的分析工具黏著度較高，較難改變；第二是進階投資人的人數較少，即便朝這個方向繼續拓展，也很難大幅增加會員數。相對的，佔大多數的中階投資人正處於想積極吸收理財資訊，並建立起投資方法的時期，相對依賴分析工具的輔助，
            <span className="highlighttext">
              因此，現階段往拓展中階投資人的方向優化產品，可能更容易吸引新使用者加入。
            </span>
          </p>
        </div>

        <div className="flex-column">
          <h4>從訪談發掘使用者洞見</h4>
          <p className="">
            確定了大方向後，我開始著手進行中階投資人的訪談，目的是進一步描繪出使用者的樣貌，讓設計決策能夠貼近真實的使用情境。我將中階使用者定義為{" "}
            <span className="highlighttext">
              曾經在分析工具的輔助下獨立做出投資判斷的投資人
            </span>
            ，並搭配其他自評問項來篩選出最貼近目標族群的訪談參與者。這個階段，我們想探索的是：
          </p>
          <ol>
            <li>
              <span className="highlighttext">使用者怎麼決定買賣一支股票</span>
              ：從更高角度看整個流程，辨識優化切入點
            </li>
            <li>
              <span className="highlighttext">
                使用者為什麼決定使用分析工具
              </span>
              ：探索使用分析工具的動機
            </li>
            <li>
              <span className="highlighttext">使用者怎麼使用分析工具 </span>
              ：探索使用分析工具的行為
            </li>
          </ol>
          <p className="">
            我依據這三個主題分別構思數個訪談項目，以確保充分探索這些主題。
          </p>
        </div>

        <div className="flex-column-center">
          <img src={interviewitems} alt="" className="contentimg" />

          <p className="caption">我將訪談主題轉化為參與者可回答的形式</p>
        </div>
        <div className="flex-column">
          <h4>分析並呈現訪談結果</h4>
          <p className="">
            在有限的時間裡，我們最後總共談了七位參與者，並將收集到的資料轉化為如下的同理心地圖，讓所有人對於目標族群的樣貌有初步的了解，並得以基於這些共同的理解發展設計決策。
          </p>
        </div>
        <div className="flex-column-center">
          <img src={empathy} alt="" className="contentimg" />
          <p className="caption">
            我訪談了7位中階投資人，並將訪談結果整理成同理心地圖與團隊成員分享
          </p>
        </div>
        <div className="flex-column">
          <p className="notitle">
            我們驚訝地發現，參與者對各種財報資訊的理解程度比預想的還要高，知道要尋找哪些指標來評估一間公司的表現，且對於資料的專業度有一定的要求。然而，問題出在選擇標的上，
            <span className="highlighttext">
              他們大多表示在判斷產業趨勢及出手時機點上遇到困難
            </span>
            。這說明，中階投資人在投資時的真正問題已經不是不熟悉理財指標資料，而是不知道怎麼判斷投資機會與進出場時機。
          </p>
        </div>
        <div className="flex-column">
          <h4>從流程找到切入點</h4>
          <p className="">
            我進一步將訪談所得的各種行為依照起心動念、聚焦、追蹤這三個時序整理，視覺化為使用旅程圖，從總體角度分析使用者在使用過程中遇到的痛點。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={CJM} alt="" className="contentimg" />
          </Zoom>
          {/* <p className="caption">
            我訪談了7位中階投資人，並將訪談結果整理成同理心地圖與團隊成員分享
          </p> */}
        </div>
        <div className="flex-column">
          <p className="notitle">
            從旅程圖看出，使用者需在眾多產業中反覆比較，以選出個股（行為3、4），導致他們從找到投資機會的興奮感中脫離，轉為挫折。另外，在持續追蹤、判斷進出場時機點的步驟也遇到困難（行為8、9）。我聚焦在這兩個最大的使用者痛點上初步建構出設計策略：
          </p>
          <ol>
            <li>
              <span className="highlighttext">幫助使用者判斷產業趨勢</span>
              ，減少在初期鎖定產業時的挫折。
            </li>
            <li>
              <span className="highlighttext">
                以股票的合理價格為個股分析的主軸
              </span>
              ，幫助使用者於追蹤階段快速判斷進出場時機。
            </li>
          </ol>
          <p className="p-end">
            這還是粗略的方向，需要進一步打磨，但是透過探索使用者的過程，我們已經可以漸漸勾勒出產品的優化方向並將其於商業目標連結。
          </p>
        </div>
        {/* <div className="flex-column">
          <h4>聚焦痛點（這次訪談的結果）使用者profile</h4>
          <p className="p-end">
            藉由這次的訪談，我們對於目標使用者更加瞭解，明白了中階投資人在投資過程中有哪些行動、期待與痛點。了解這些讓我們在後續的設計決策得以有依歸，而不只是基於假設，對於確實達到改版目標有非常大的幫助。
          </p>
        </div> */}
        <div>
          <div className="flex-column summary">
            <h4>📌 總結 📌</h4>
            <p>
              這個階段我們從投資經驗的角度，聚焦了目標使用者，並透過訪談探索使用者的行為：
            </p>
            <ol>
              <li>聚焦在中階投資人以達成商業目標</li>
              <li>以同理心地圖理解中階投資人的行為、想法、情緒</li>
              <li>以使用者旅程圖辨識出痛點與切入機會</li>
            </ol>
          </div>
        </div>
        <div className="sectionend"></div>
      </div>

      {/* ------------------------------------ section06 Refine the mission  ----------------------------------------------- */}

      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 06</span>
          <h3>Refine the mission </h3>
        </div>
        <div className="flex-column">
          <p className="">
            為了探索產品的優化方向，我們對現有產品及競品進行研究，並訪談使用者辨識痛點。投資專案資源在這一連串活動中，
            讓我得以將商業目標與使用者需求連結，把抽象的目標精煉為一個具體的解決方案：
            <span className="highlighttext">
              在產品中凸顯產業趨勢及進出場分析功能，吸引更多中階投資人，達到增加產品用戶的目的。
            </span>
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={focus} alt="" className="contentimg" />
          </Zoom>
          {/* <p className="caption">
            我訪談了7位中階投資人，並將訪談結果整理成同理心地圖與團隊成員分享
          </p> */}
        </div>
        <div className="flex-column">
          <p className="">
            我將這個執行策略拆解為產業趨勢分析及進出場分析兩個子項目，並加上從現有產品稽核與競品分析得來的洞見，總共三個部分，針對各部分明確定義出可以被驗證的達成標準，
            <span className="highlighttext">
              讓後續的設計決策始終與目標連結
            </span>
            ：
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={functionspec} alt="" className="contentimg" />
          </Zoom>
          {/* <p className="caption">
            我訪談了7位中階投資人，並將訪談結果整理成同理心地圖與團隊成員分享
          </p> */}
        </div>
        <div className="flex-column">
          <ol>
            <li>
              <span className="highlighttext">
                產業趨勢判斷功能旨在減少使用者初期選股的挫折
              </span>
              ，用明確的指標協助使用者判斷當前產業的發展，並確保流程的連貫性，讓使用者判斷出有前景的產業後，可以很順利的判斷並連結至想進一步了解的股票。
            </li>
            <li>
              <span className="highlighttext">
                進出場追蹤判斷功能旨在協助使用者判斷進出場時機
              </span>
              ，在個股頁面重點呈現合理價格，並且提供追蹤功能，讓使用者者以快速的找到在觀察的股票。
            </li>
            <li>
              加上
              <span className="highlighttext">
                其他現有產品稽核及競品分析中發掘的洞見
              </span>
              ，包含以視覺區塊區隔不同的資訊，降低使用者的視覺負擔；減少瀏覽同個股的內容時另開頁面的次數，降低使用者的導航負擔等。
            </li>
          </ol>
        </div>
        <div className="flex-column">
          <p className="p-end">
            以上三點清楚闡明了這次改版最終產品會包含的抽象特性及衡量標準，讓我得以開始依序設計產品的資訊架構、使用流程及視覺介面，把抽象的概念轉化為具體的產品。
          </p>
        </div>
        <div className="sectionend"></div>
      </div>
      {/* ------------------------------------ section06 Refine the mission  ----------------------------------------------- */}
      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 07</span>
          <h3>Redesign: 資訊架構 </h3>
        </div>
        <div className="flex-column">
          <p className="">
            對資訊架構的重整是連結商業目標與使用者需求的重要基礎，舊版資訊架構有三個主要問題：
            <ol>
              <li>
                <span className="highlighttext">
                  產業分析到個股分析間有斷點
                </span>
                ：舊版資訊架構中，產業分析相關內容分為產業分析、類股分析兩個項目，其中只有產業分析能連結到個股頁面，流程出現明顯的斷點，無法幫助使用者順利聚焦選股。
              </li>
              <li>
                <span className="highlighttext">
                  使用者無法從概覽頁面快速鎖定內容
                </span>
                ：缺乏讓使用者能夠快速概覽內容的頁面，導致需要逐個點擊頁面才能看到所有內容。
              </li>

              <li>
                <span className="highlighttext">沒有區分出不同層級的頁面</span>
                ：所有頁面被放在同層級中，讓使用者不容易理解網站架構。
              </li>
            </ol>
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={IAold} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            為了讓產品達成改版目標，必需優化舊版資訊架構。
          </p>
        </div>
        <div className="flex-column">
          <p className="">
            帶著清晰的改版目標，我開始打造新的資訊架構，
            <span className="highlighttext">
              由上而下思考頂層結構，確保與目標連結
            </span>
            。最上層架構需讓使用者容易辨別出產品的核心內容，如台股總覽分析、產業分析等，且避免易混淆的分級方式，類股分析、產業分析等有從屬關係的內容避免並列在同級結構中。
          </p>
          <p className="">
            另一方面，由於這次改版涉及到產品方向的轉變，若只以頁面作為資訊架構的最小節點（node）可能不足以對底層細節做出有意義的規劃，因此我進一步解構頁面內容為更細的資訊模塊，以這些資訊模塊為最小節點，
            <span className="highlighttext">
              思考最符合使用者需求的資訊組織方式
            </span>
            。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={IAcompare} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">新版的資訊架構更貼近此次的改版目標。</p>
        </div>
        <div className="flex-column">
          <p className="">改版後的資訊架構讓使用者可以：</p>
          <ol>
            <li>
              <span className="highlighttext">
                沒有斷點的從產業趨勢分析聚焦到個股，讓選股的過程更直覺
              </span>
              。
            </li>
            <li>
              <span className="highlighttext">
                在總覽頁概覽重要內容而不需要不停切換頁面，降低導航負擔
              </span>
              。
            </li>

            <li>
              <span className="highlighttext">
                呈現出資訊的層級關係，更容易理解網站內容，降低初次使用的門檻
              </span>
              。
            </li>
          </ol>
          <p className="p-end">
            這三個優化項目是這次改版專案的基礎，從根本改變產品方向，對中階投資人更友善，且讓後續更細節的設計不會偏離方向。
          </p>
        </div>

        <div className="sectionend"></div>
      </div>
      <div className="container flex-column-center">
        <div className=" casetitle ">
          <span>chapter 08</span>
          <h3>Redesign: Wireflow </h3>
        </div>
        <p className="">
          有了堅實的資訊架構基礎後，我進一步設計產品的wireframe，
          <span className="highlighttext">
            開始將抽象的概念及需求轉化為具體可見的介面
          </span>
          ，這些內容包含各頁面間的導航方式、頁面中的資訊呈現方式以及功能的操作介面設計。以下是一些這個階段溝通的重點內容：
        </p>
        <div className="flex-column-center">
          <Zoom>
            <img src={wireframe01} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            溝通使用者如何在在不同的頁面、資訊內容中移動，包含側邊導航、同層級頁面內容的導航及頁面間的導航方式。良好的導航方式讓易用性大幅提高，是產品設計中的重點。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={wireframe02} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            溝通分析工具中不同數據、資訊的呈現方式，思考如何轉化為使用者能最輕鬆吸收的形式。
          </p>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={wireframe03} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">溝通產品中重點功能的操作流程與互動方式。</p>
        </div>
        <p className="notitle">
          最後，我將所有的wireframe統整為一份wireflow文件，溝通各頁面的內容及如何在頁面間移動，作為接下來視覺介面設計的指引。
        </p>
        <div className="flex-column-center">
          <Zoom>
            <img src={mockuptest} alt="" className="contentimg" />
          </Zoom>
          <p className="caption">
            我將各頁面的wireframe統整為一份wireflow文件，溝通各頁面的內容及如何在頁面間移動
          </p>
        </div>
        {/* <div className="flex-column-center">
          <img src={mockuptest} alt="" className="contentimg" />
          <p className="caption">
            新版的資訊架構顯得稍微複雜，但卻更貼近此次的改版目標。
          </p> 
        </div> */}
        {/* <div className="flex-column-center">
          <img src={mockuptest} alt="" className="contentimg-fluid" />
          <p className="caption">
            新版的資訊架構顯得稍微複雜，但卻更貼近此次的改版目標。
          </p>
        </div> */}
      </div>
      <div className="container flex-column-center" ref={myRef}>
        <div className=" casetitle ">
          <span>Final Work</span>
          <h3>Introducing FindBillion</h3>
        </div>

        <div className="flex-column-center">
          <Zoom>
            <img src={intro2} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={pages} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={Before} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={components} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={feature01} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="flex-column-center">
          <Zoom>
            <img src={feature02} alt="" className="contentimg-intro" />
          </Zoom>
        </div>
        <div className="sectionend"></div>
      </div>
    </div>
  );
};

export default Project1;
