// import React from "react";
import React, { useEffect, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Header from "./component/Header";
import About from "./component/About";
import Contact from "./component/Contact";
import Playground from "./component/Playground";
import Footer from "./component/Footer";
import Project1 from "./component/Project1";
import Project2 from "./component/Project2";
import Project3 from "./component/Project3";
import ProjectListpage from "./component/ProjectListpage";
import Scrolltotop from "./component/Scrolltotop";

const App = () => {
  return (
    <Router>
      <div className="">
        <Header />
      </div>
      <div>
        <Fragment>
          <Scrolltotop />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/work">
              <ProjectListpage />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/playground">
              <Playground />
            </Route>
            <Route exact path="/project1">
              <Project1 />
            </Route>
            <Route exact path="/project2">
              <Project2 />
            </Route>
            <Route exact path="/project3">
              <Project3 />
            </Route>
          </Switch>
        </Fragment>
      </div>
      <div className="">
        <Footer />
      </div>
    </Router>
  );
};

export default App;
