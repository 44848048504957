import React from "react";
import { RiPaintBrushFill } from "react-icons/ri";
import inprogress from "../assets/inprogress.webp";

const Inprogress = () => {
  return (
    <div className="container-inprogress">
      <div className="inprogress-box">
        <img src={inprogress} className="inprogress-img"></img>

        {/* <div className="inprogress-text">Coming Soon</div> */}
      </div>
    </div>
  );
};

export default Inprogress;
