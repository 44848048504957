import React from "react";
import Inprogress from "./Inprogress";
import { animated, useSpring } from "@react-spring/web";
import { useState } from "react";
import { HiScale } from "react-icons/hi";
import ButtonIcon from "./ButtonIcon";
import ButtonLock from "./ButtonLock";

const ProjectItem = ({
  project,
  tittle,
  discription,
  role,
  img,
  BtnText,
  color,
  lock,
}) => {
  const [toggle, setToggle] = useState(false);
  const styles = useSpring({
    backgroundSize: toggle ? "auto 115%" : "auto 110%",
    config: { mass: 1, tension: 200, friction: 20 },
  });
  return (
    <animated.a
      className={`projectItemContainer ${img}`}
      style={{ ...styles }}
      // href={lock == "true" ? null : project}
      onMouseEnter={() => setToggle((prev) => !prev)}
      onMouseLeave={() => setToggle((prev) => !prev)}
    >
      <div className="projectItemContainerText">
        <h4>{role}</h4>
        <h3 style={{ color: ` ${color}` }}>{tittle}</h3>
        <p style={{ color: ` ${color}` }}>{discription}</p>
        {lock == "true" ? (
          <ButtonLock text={BtnText} state="" />
        ) : (
          <ButtonIcon text={BtnText} state="" to={project} />
        )}
      </div>
      <div className="projectItemContainerImg">
        {/* <img src={img} alt="" srcset="" width="100%" /> */}
      </div>
    </animated.a>
  );
};

export default ProjectItem;
