import React from "react";
import { useState } from "react";
import { HiOutlineArrowCircleRight } from "react-icons/hi";
import { animated, useSpring } from "@react-spring/web";
import { Link } from "react-router-dom";

const ButtonIcon = ({ text, state, to, scroll }) => {
  const [toggle, setToggle] = useState(false);
  const styles = useSpring({
    transform: toggle ? "translateX(6px)" : "translateX(0px)",
    config: { mass: 1, tension: 250, friction: 10 },
  });
  return (
    <Link
      className="mybtn "
      to={to}
      // onClick={scroll}
      onMouseEnter={() => setToggle((prev) => !prev)}
      onMouseLeave={() => setToggle((prev) => !prev)}
    >
      <Link to={to}>{text}</Link>
      <animated.div className="flex-row" style={{ ...styles }}>
        <HiOutlineArrowCircleRight
          style={{
            fontSize: "20px",
            marginLeft: "10px",
            color: "white",
          }}
        />
      </animated.div>
    </Link>
  );
};

export default ButtonIcon;
